<!--
程序名：问题设计页面
功能：对问卷中问题的添加、编辑、删除
-->
<template>
  <div class="Design" v-loading="loading" element-loading-text="加载中...">
    <h3>{{ title }}</h3>
    <div class="top" v-if="desc !== ''">
      {{ desc }}
    </div>
    <draggable v-model="detail" :move="moveList" @change="moveEvent">
      <el-card
        class="box-card"
        v-for="(item, index) in detail"
        :key="item.orderIndex"
        style="margin: 10px"
      >
        <div slot="header" class="clearfix">
          <div class="questionTitle">
            <!--显示必填标识-->
            <span style="color: #f5222d">
              <span v-if="item.quesRequired === '1'">*</span>
              <span v-else>&nbsp;</span>
            </span>
            <span style="color: black; margin-right: 3px">{{
              item.orderIndex + 1 + "."
            }}</span>
            {{ item.quesDesc }}
          </div>
          <div style="float: right">
            <el-button
              style="padding: 2px"
              type="text"
              @click="editorQuestion(item)"
              >编辑</el-button
            >
            <el-button
              style="padding: 2px; color: #f5222d"
              type="text"
              @click="deleteQuestion(index)"
              >删除</el-button
            >
          </div>
        </div>

        <!--单选题展示-->
        <div
          class="text item"
          v-if="item.quesType === '1'"
          v-for="(option, index) in (item.quesOption || '').split(',')"
          :key="index"
        >
          <el-radio
            v-model="item.radioValue"
            :label="index"
            style="margin: 5px"
          >
            <span
              style="
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                word-wrap: break-word;
              "
            >
              {{ option }}
            </span>
          </el-radio>
        </div>

        <!--多选题展示-->
        <el-checkbox-group
          v-if="item.quesType === '2' || item.quesType === '3'"
          v-model="checkbox[index]"
          @change="multiTextClick($event, index)"
        >
          <div
            class="text item"
            v-for="(option, optIndex) in (item.quesOption || '').split(',')"
            :key="optIndex"
          >
            <el-checkbox :label="option" style="margin: 5px"></el-checkbox>
            <el-input
              @input="
                (value) => {
                  multiTextInput(value, checkbox[index]);
                }
              "
              v-if="
                item.quesType === '3' &&
                optIndex === (item.quesOption || '').split(',').length - 1
              "
              v-model="checkboxTexts[index]"
              resize="none"
              maxlength="500"
              style="margin-left: 4%; width: 96%"
            >
            </el-input>
          </div>
        </el-checkbox-group>

        <!--填空题展示-->
        <el-input
          v-if="item.quesType === '4'"
          type="textarea"
          autosize
          maxlength="500"
          resize="none"
          v-model="item.textValue"
        >
        </el-input>
      </el-card>
    </draggable>

    <!-- 总题量有最大限制：maxQuestLen -->
    <el-tooltip
      class="item"
      effect="dark"
      :content="'最多设置 ' + maxQuestLen + ' 道问题'"
      placement="bottom"
    >
      <el-button
        :disabled="detail.length >= maxQuestLen"
        icon="el-icon-circle-plus"
        @click="addQuestion"
        style="margin-top: 10px"
        >添加题目</el-button
      >
    </el-tooltip>

    <br /><br /><br /><br /><br />

    <!--添加/编辑题目弹窗-->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="outerVisible"
      :close-on-click-modal="false"
      class="dialog"
      v-loading="dialogLoading"
    >
      <!-- 内层弹窗 -->
      <el-dialog
        width="60%"
        title="题库搜索"
        :close-on-click-modal="false"
        :visible.sync="innerVisible"
        append-to-body
        class="dialog"
      >
        <div>
          <div>
            <span class="sub-title">题库搜索</span>
            <el-input
              v-model="keywords"
              clearable
              placeholder="请输入关键字从题库搜索"
              maxlength="500"
              style="width: 60%"
            >
            </el-input>
          </div>
          <br />
          <div>
            <span class="sub-title">问题标签</span>
            <el-select
              v-model="searchTags"
              filterable
              clearable
              multiple
              placeholder="请选择问题标签"
              style="width: 60%"
            >
              <el-option
                v-for="(item, index) in tagOptions"
                :key="index"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-button
              type="primary"
              @click="searchInLibrary()"
              style="display: inline-block; float: right; margin-bottom: 0"
              >查询</el-button
            >
          </div>
        </div>
        <el-divider></el-divider>
        <!-- 嵌套搜索结果表格 -->
        <!-- 选中记录，点击下方完成，使用该题 -->
        <div>
          <!-- 表格 -->
          <el-table
            :data="libraryQuestions"
            @selection-change="handleSelectionChange"
            v-loading="innerLoading"
          >
            <el-table-column type="selection"></el-table-column>
            <el-table-column
              label="标题"
              prop="quesDesc"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              label="标签"
              prop="tagsName"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column label="题型" prop="quesType">
              <template slot-scope="scope">
                <template v-if="scope.row.quesType === '1'">单选题</template>
                <template
                  v-if="
                    scope.row.quesType === '2' || scope.row.quesType === '3'
                  "
                  >多选题</template
                >
                <template v-if="scope.row.quesType === '4'">问答题</template>
              </template>
            </el-table-column>
            <el-table-column
              label="选项"
              prop="quesOption"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column label="是否必选 / 填" prop="quesRequired">
              <template slot-scope="scope">
                <template v-if="scope.row.quesRequired === '0'">否</template>
                <template v-if="scope.row.quesRequired === '1'">是</template>
              </template>
            </el-table-column>
            <el-table-column label="是否为身份证采集" prop="idType">
              <template slot-scope="scope">
                <template v-if="scope.row.idType === '0'">否</template>
                <template v-if="scope.row.idType === '1'">是</template>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 表格分页 -->
        <div style="width: 100%; text-align: right">
          <el-pagination
            :page-size="paginationInfo.pageSize"
            layout="prev, pager, next"
            :pager-count="paginationInfo.pagerCount"
            :page-count="paginationInfo.pageCount"
            :hide-on-single-page="paginationInfo.pageCount === 1"
            @current-change="handlePageChange"
            :current-page="parseInt(paginationInfo.currentPage)"
          >
          </el-pagination>
        </div>
        <div style="width: 100%; text-align: right">
          <span :style="questionNumNoticeStyle"
            >题数限制：{{ questionNum }}/20</span
          >
          <el-button style="margin-left: 10px" @click="innerVisible = false"
            >取消</el-button
          >
          <el-button
            type="primary"
            style="margin-left: 10px"
            @click="addQuestionFromLibrary"
            >完成</el-button
          >
        </div>
      </el-dialog>

      <el-row>
        <!-- 点击弹窗 -->
        <el-button
          v-if="!isQuestionChange"
          type="primary"
          @click="openInnerDialog"
          >从题库搜索
        </el-button>
      </el-row>

      <el-divider></el-divider>

      <el-form ref="form" :model="willAddQuestion" label-width="80px">
        <el-form-item label="题目类型" style="width: 100%">
          <!-- 不允许修改题的类型 -->
          <el-select
            :disabled="willAddQuestion.id"
            v-model="willAddQuestion.type"
            placeholder="请选择题目类型"
            @change="typeChange"
            style="width: 98%"
          >
            <el-option
              v-for="(item, index) in allType"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否必填" style="width: 100%">
          <el-checkbox v-model="willAddQuestion.must">必填</el-checkbox>
        </el-form-item>
        <el-form-item label="题目标签" style="width: 100%">
          <el-select
            v-model="willAddQuestion.tags"
            filterable
            allow-create
            clearable
            multiple
            default-first-option
            placeholder="请选择问题标签"
            style="width: 98%"
          >
            <el-option
              v-for="(item, index) in tagOptions"
              :key="index"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="题目标题" style="width: 100%">
          <el-input
            type="textarea"
            autosize
            v-model="willAddQuestion.title"
            placeholder="请输入标题"
            maxlength="500"
            show-word-limit
            style="width: 98%"
          ></el-input>
        </el-form-item>

        <template v-if="willAddQuestion.type === '1'">
          <el-form-item
            :label="'选项' + String.fromCharCode(65 + index)"
            v-for="(item, index) in willAddQuestion.options"
            :key="index"
          >
            <el-row>
              <el-col :span="16">
                <el-input
                  @input="(e) => (item.title = validForbid(e))"
                  type="textarea"
                  autosize
                  v-model="item.title"
                  placeholder="请输入选项名"
                  maxlength="500"
                  show-word-limit
                  style="width: 90%"
                ></el-input>
              </el-col>
              <el-col :span="8">
                <el-button
                  type="danger"
                  plain
                  class=""
                  @click="deleteOption(index)"
                  >删除选项</el-button
                >
              </el-col>
            </el-row>
          </el-form-item>
          <!-- 选项个数有最大限制：maxOptLen -->
          <el-button
            type="primary"
            :disabled="willAddQuestion.options.length >= maxOptLen"
            plain
            class="addOptionButton"
            @click="addOption"
            >新增选项</el-button
          >
        </template>
        <template
          v-else-if="
            willAddQuestion.type === '2' || willAddQuestion.type === '3'
          "
        >
          <el-form-item
            :label="'选项' + String.fromCharCode(65 + index)"
            v-for="(item, index) in willAddQuestion.options"
            :key="index"
            class="options"
          >
            <el-row>
              <el-col :span="16">
                <el-input
                  @input="(e) => (item.title = validForbid(e))"
                  type="textarea"
                  autosize
                  v-model="item.title"
                  placeholder="请输入选项名"
                  maxlength="500"
                  show-word-limit
                  style="width: 90%"
                ></el-input>
              </el-col>
              <el-col :span="8">
                <el-button type="danger" plain @click="deleteOption(index)"
                  >删除选项
                </el-button>
                <!-- 增加一个字段，判断当前选项是否为可手动填写，注意，应该限制只允许最后一个选项可填 -->
                <!-- 当可填项有变动（包括但不限于不可填变为可填、可填变为不可填），需要检查 -->
                <!-- 在某个时间，应根据选项的fillable值是否为true，来标注该题是普通多选题还是混合模式的多选题 -->
                <el-checkbox
                  v-model="willAddQuestion.fillable"
                  style="margin-left: 20px"
                  v-if="index === willAddQuestion.options.length - 1"
                  >可填
                </el-checkbox>
              </el-col>
            </el-row>
          </el-form-item>
          <!-- 选项个数有最大限制：maxOptLen -->
          <el-button
            type="primary"
            :disabled="willAddQuestion.options.length >= maxOptLen"
            plain
            class="addOptionButton"
            @click="addOption"
            >新增选项</el-button
          >
        </template>
        <template v-if="willAddQuestion.type === '4'">
          <el-form-item label="填空">
            <el-input
              type="textarea"
              autosize
              maxlength="500"
              style="width: 98%"
            >
            </el-input>
          </el-form-item>
        </template>
      </el-form>
      <br />
      <div style="width: 100%; text-align: right">
        <el-button style="margin-left: 10px" @click="outerVisible = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          style="margin-left: 10px"
          @click="checkAddQuestion"
          >完成</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getToken } from "@/api/user.js";
import draggable from "vuedraggable";
import {
  getQuestionsByPaperId,
  getSimilarQuestions,
  addQuestion,
  updateQuestion,
  updateOrders,
  delQuestion,
  addTag,
  getTags,
  addQuestionFromLibrary,
} from "@/api/api.js";
export default {
  components: {
    draggable,
  },
  created() {
    // 初始化多选框的数组用于保存用户的选项
    for (let index = 0; index < 20; index++) {
      this.$set(this.checkbox, index, []);
    }
  },

  mounted() {
    getTags().then((data) => {
      this.tagOptions = data.data.tags;
    });
  },

  computed: {
    // 当前拥有的题数，包括题库选择的和已有的
    questionNum() {
      return this.detail.length + this.multipleSelection.length;
    },
    // 题目数量提示的样式
    questionNumNoticeStyle() {
      if (this.questionNum <= 20) {
        return {
          color: "black",
        };
      } else {
        return {
          color: "red",
        };
      }
    },
  },

  data() {
    return {
      oldObj: "", // 保存问题信息，用于问题修改后进行比较是否存在修改
      paginationInfo: {
        // 分页信息
        pageSize: 6, // 每页数据个数
        pageCount: 1, // 页数
        pagerCount: 5, // 页码按钮的数量,大于3后的会省略
        currentPage: 1, // 当前选择页
      },
      status: "", // 问卷状态 0已发布 1编辑中
      isQuestionChange: false, // 是否为问题修改
      checkbox: [], // 存放多选题选择项的数组
      checkboxTexts: [], // 存放混合问答多选填写的文本
      multipleSelection: [], // 从题库中选择的题
      libraryQuestions: [], // 从题库中查询到的题
      maxQuestLen: 20, // 每套问卷最多20道题
      maxOptLen: 20, // 每道题最多20个选项
      allQuestions: [], // 题库的问题列表
      keywords: "", // 搜索题库关键词
      tagOptions: [], // 用于标签下拉框显示
      searchTags: [], // 用于保存标签下拉框中选择的标签
      loading: false, // 页面加载中
      innerLoading: false, // 题库表格的加载效果
      dialogLoading: false, // 弹窗的加载效果
      outerVisible: false,
      innerVisible: false,
      dialogTitle: "",
      detail: [],
      wjId: 0,
      title: "",
      desc: "",
      willAddQuestion: {
        id: 0,
        type: "",
        title: "",
        options: [
          {
            title: "", // 选项标题
            id: 0, // 选项id
          },
        ],
        row: 1,
        must: false, // 是否必填
        tags: [], // 题目标签
        idType: "", // 是否为身份证号采集题目
        fillable: "", // 判断多选题中存在可输入情况
        isLib: "",
      },
      allType: [
        {
          value: "1",
          label: "单选题",
        },
        {
          value: "2",
          label: "多选题",
        },
        {
          value: "4",
          label: "填空题",
        },
      ],
      isWarningShown: false, // 控制提示是否已经显示过
    };
  },

  methods: {
    // 混合多选输入框检测到输入
    multiTextInput(newValue, checkbox) {
      if (newValue) {
        if (checkbox.indexOf("D:其他") === -1) {
          checkbox.push("D:其他");
        }
      } else {
        let index = checkbox.indexOf("D:其他");
        checkbox.splice(index, 1);
      }
    },

    // 混合多选选择项改变
    multiTextClick(newValue, index) {
      if (newValue.indexOf("D:其他") === -1) {
        this.checkboxTexts.splice(index, 1);
      }
    },

    // 打开题库弹框
    async openInnerDialog() {
      this.innerLoading = true;
      this.innerVisible = true;
      let res = await getSimilarQuestions({
        tagIds: "",
        title: "",
        page: 1,
      });
      if (res.code === 200) {
        this.libraryQuestions = res.data.questions.records;
        this.paginationInfo.pageCount = Math.ceil(res.data.questions.total / 6);
        this.paginationInfo.currentPage = res.data.questions.current;
      } else {
        this.libraryQuestions = [];
      }
      this.innerLoading = false;
    },

    // 处理当前页修改
    async handlePageChange(val) {
      this.innerLoading = true;
      let tagIds = "";
      this.searchTags.forEach((value, index) => {
        if (tagIds === "") {
          tagIds = value;
        } else {
          tagIds = tagIds + "," + value;
        }
      });

      let res = await getSimilarQuestions({
        tagIds,
        title: this.keywords,
        page: val,
      });
      if (res.code === 200) {
        this.libraryQuestions = res.data.questions.records;
        this.paginationInfo.pageCount = Math.ceil(res.data.questions.total / 6);
        this.paginationInfo.currentPage = res.data.questions.current;
      } else {
        this.libraryQuestions = [];
      }

      this.innerLoading = false;
    },

    // 从题库中选择题目添加
    async addQuestionFromLibrary() {
      // 检查题数是否超过上限
      if (this.questionNum > 20) {
        this.$message({
          type: "info",
          message: "题数超过限制",
        });
        return;
      }

      // 往数据库存问题
      let submitArr = [];
      // 构造传递参数
      for (let index = 0; index < this.multipleSelection.length; index++) {
        const element = this.multipleSelection[index];

        // 比对已有的问题，限制身份证采集题只有一个
        for (let var2 = 0; var2 < this.detail.length; var2++) {
          const const1 = this.detail[var2];
          if (element.idType === const1.idType && const1.idType === "1") {
            this.$message({
              type: "info",
              message: "身份证采集题限制只能存在一个",
            });
            return;
          }
        }

        submitArr[index] = {
          idType: element.idType,
          paperId: this.wjId,
          quesDesc: element.quesDesc,
          quesOption: element.quesOption,
          quesRequired: element.quesRequired,
          quesType: element.quesType,
          tag: element.tag,
          isLib: "0",
          orderIndex: index + 1,
        };
      }

      if (submitArr.length === 0) {
        this.$message({
          type: "info",
          message: "至少选择一道题方可添加",
        });
      }
      console.log("kankankknaknakn", submitArr);

      let res = await addQuestionFromLibrary(submitArr);

      if (res.code === 200) {
        this.$message({
          type: "success",
          message: "保存成功",
        });
      } else if (res.code === 401) {
        this.toLogin();
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }

      // 弹框关闭
      this.innerVisible = false;
      this.outerVisible = false;
      // 刷新问题列表
      this.getQuestionList();
      // 重置搜索栏
      this.searchTags = [];
      this.libraryQuestions = [];
      // 重置分页
      this.paginationInfo = {
        pageSize: 6,
        pageCount: 1,
        pagerCount: 5,
        currentPage: 1,
      };
    },
    // 不允许拖拽
    moveList(e) {
      if (this.status === "0") {
        if (!this.isWarningShown) {
          this.isWarningShown = true; // 标记提示已经显示过
          this.$message({
            type: "info",
            message: "请暂停问卷后进行编辑",
          });
        }
        return false;
      }
    },
    // 题库拖拽
    async moveEvent(e) {
      let data = e.moved.element;
      this.isWarningShown = false;
      let res = await updateOrders({
        id: data.id,
        newIndex: e.moved.newIndex,
      });
      if (res.code === 200) {
        this.$message({
          type: "success",
          message: "调整成功",
        });
        let res = await getQuestionsByPaperId({
          paperId: this.wjId,
        });

        if (res.code === 200) {
          this.detail = res.data.paper.questions;
        } else if (res.code === 401) {
          this.toLogin();
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      } else if (res.code === 401) {
        this.toLogin();
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    // 题库检索后题目选择
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    // 获取题库中的题
    async searchInLibrary() {
      this.innerLoading = true;
      this.paginationInfo.currentPage = "1";
      // 根据keywords和tag进行筛选
      let tagIds = "";
      this.searchTags.forEach((value, index) => {
        if (tagIds === "") {
          tagIds = value;
        } else {
          tagIds = tagIds + "," + value;
        }
      });

      let res = await getSimilarQuestions({
        tagIds,
        title: this.keywords,
        page: this.paginationInfo.currentPage,
      });
      if (res.code === 200) {
        this.libraryQuestions = res.data.questions.records;
        this.paginationInfo.pageCount = Math.ceil(res.data.questions.total / 6);
        this.paginationInfo.currentPage = res.data.questions.current;
      } else {
        this.libraryQuestions = [];
      }

      this.innerLoading = false;
    },

    // 初始化问卷所有问题
    init(wjId, status, title, desc) {
      if (!wjId) {
        this.detail = [];
        return;
      }

      this.wjId = wjId;
      this.title = title;
      this.status = status;
      this.desc = desc;
      this.getQuestionList();
    },

    // 获取问题列表(问卷内容)
    async getQuestionList() {
      this.detail = [];
      this.loading = true;

      let res = await getQuestionsByPaperId({
        paperId: this.wjId,
      });

      if (res.code === 200) {
        this.detail = res.data.paper.questions;
        this.loading = false;
      } else if (res.code === 401) {
        this.toLogin();
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },

    // 点击添加问题按钮
    addQuestion() {
      if (this.wjId === 0 || this.wjId === null) {
        this.$message({
          type: "info",
          message: "请先创建问卷",
        });
        return;
      }

      if (this.status === "0") {
        this.$message({
          type: "info",
          message: "请暂停问卷后进行编辑",
        });
        return;
      }

      this.dialogTitle = "添加题目";
      this.isQuestionChange = false;
      this.willAddQuestion = {
        id: 0,
        type: "",
        title: "",
        options: [
          {
            title: "",
            id: 0,
          },
        ],
        row: 1,
        must: false,
        tags: [],
        isLib: "1",
        idType: "",
        fillable: "",
      };
      this.outerVisible = true;
    },

    // 删除问题
    deleteQuestion(index) {
      if (this.status === "0") {
        this.$message({
          type: "info",
          message: "请暂停问卷后进行编辑",
        });
        return;
      }

      this.$confirm("确定删除此题目?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 删除题目
          delQuestion({
            questionId: this.detail[index].id,
          }).then((data) => {
            if (data.code === 200) {
              this.detail.splice(index, 1);
              this.$message({
                type: "success",
                message: "删除成功",
              });
            } else if (data.code === 401) {
              this.toLogin();
            } else {
              this.$message({
                type: "error",
                message: data.message,
              });
            }
          });
        })
        .catch((res) => {
          console.log(res);
        });
    },

    // 判断各个选项是否有内容
    checkOptionsHasContent(options) {
      for (let index = 0; index < options.length; index++) {
        const element = options[index];
        if (element.title == "") {
          // title为空不存在内容返回false
          return false;
        }
      }

      return true;
    },

    // 判断添加题目是否填写数据
    willAddQuestionHasData() {
      // 检查标签、类型、标题是否有值
      if (
        this.willAddQuestion.tags.length === 0 ||
        this.willAddQuestion.title == "" ||
        this.willAddQuestion.type == ""
      ) {
        return false;
      } else {
        // 检查是否存在选项
        if (
          !this.checkOptionsHasContent(this.willAddQuestion.options) &&
          this.willAddQuestion.type !== "4"
        ) {
          return false;
        }
        return true;
      }
    },

    // 检查选择可填后选项title是否为其他
    limitFillable() {
      let options = this.willAddQuestion.options;
      if (
        this.willAddQuestion.fillable &&
        options[options.length - 1].title !== "其他"
      ) {
        return false;
      }
      return true;
    },

    // 检查题目选项是否大于0
    limitOptionsMoreThenZero() {
      // 问答题没有选项
      if (this.willAddQuestion.type === "4") {
        return true;
      }
      let options = this.willAddQuestion.options;
      if (options.length < 1) {
        return false;
      }
      return true;
    },

    // 检查有没有新的标签，有新的标签就新增标签
    async addTagIfHaveNewTag() {
      // 问题的标签
      const questionTags = this.willAddQuestion.tags;
      // 数据库的标签
      const databaseTags = this.tagOptions;
      // 待新增的标签对象列表
      let newTags = [];

      // 外层对问题的标签进行循环
      for (let outerIndex = 0; outerIndex < questionTags.length; outerIndex++) {
        let tagExist = false;
        const questionTagName = questionTags[outerIndex];
        // 内层对数据库的标签进行循环
        for (
          let innerIndex = 0;
          innerIndex < databaseTags.length;
          innerIndex++
        ) {
          const databaseTag = databaseTags[innerIndex];
          if (questionTagName === databaseTag.name) {
            // 走到这一步说明数据库有这个标签，跳出内层循环去判断问题的下一个标签
            tagExist = true;
            break;
          }
        }

        // 没有标签则在newTags中加入需要添加的新的标签
        if (!tagExist) {
          newTags.push({ name: questionTagName, type: 0 });
        }
      }

      // newTags中有值则调用新增标签的接口
      if (newTags.length > 0) {
        let res = await addTag(newTags);
        if (res.code === 200) {
          // 添加完成后刷新当前的标签列表
          res = await getTags();
          if (res.code === 200) {
            this.tagOptions = res.data.tags;
          }
        }
      }
    },

    // 确认添加/保存题目
    async checkAddQuestion() {
      this.dialogLoading = true;

      // 此处对标签进行验证
      await this.addTagIfHaveNewTag();

      // 此处是为了解决当用户先选择单选或多选后，再选择填空题时，在options中存在无效值的问题
      if (this.willAddQuestion.type === "4") {
        this.willAddQuestion.options = [];
      }

      // 填空题和单选题没有可填选项，需将fillable置false
      if (
        this.willAddQuestion.type === "4" ||
        this.willAddQuestion.type === "1"
      ) {
        this.willAddQuestion.fillable = false;
      }

      // 限制选项中不能有英文逗号
      if (!this.limitCommaInOptions()) {
        this.$message({
          type: "info",
          message: "题目选项中不可包含英文逗号",
        });
        this.dialogLoading = false;
        return;
      }

      // 限制选项个数大于0
      if (this.willAddQuestion.type !== "4") {
        // 非填空题需要限制
        if (!this.limitOptionsMoreThenZero()) {
          this.$message({
            type: "info",
            message: "请添加题目选项",
          });
          this.dialogLoading = false;
          return;
        }
      }

      // 限制选项可填后选项标题为'其他'
      if (!this.limitFillable()) {
        this.$message({
          type: "info",
          message: "选项可填后选项标题限制为【其他】",
        });
        this.dialogLoading = false;
        return;
      }

      // 检查填写
      if (!this.willAddQuestionHasData()) {
        this.$message({
          type: "info",
          message: "请确保题目类型、题目标签、题目标题、题目选项已选择或输入",
        });
        this.dialogLoading = false;
        return;
      }

      // 限制身份采集题只能为填空题
      if (
        this.isGetIdNumber(this.willAddQuestion.tags) === "1" &&
        this.willAddQuestion.type !== "4"
      ) {
        this.$message({
          type: "info",
          message: "身份证采集题只能为填空题",
        });
        this.dialogLoading = false;
        return;
      }

      // 添加问题
      if (!this.isQuestionChange) {
        // 限制身份证采集题只有一个
        for (let index = 0, count = 0; index < this.detail.length; index++) {
          const element = this.detail[index];
          if (element.idType === "1") {
            count++;
          }
          if (
            count === 1 &&
            this.isGetIdNumber(this.willAddQuestion.tags) === "1"
          ) {
            this.$message({
              type: "info",
              message: "身份证采集题限制只能存在一个",
            });
            this.dialogLoading = false;
            return;
          }
        }

        let res = await addQuestion({
          // 根据标签判断是否为身份证采集题目
          idType: this.isGetIdNumber(this.willAddQuestion.tags),
          isLib: this.willAddQuestion.isLib,
          paperId: this.wjId,
          quesDesc: this.willAddQuestion.title,
          quesOption: this.optionsToString(this.willAddQuestion.options),
          quesRequired: this.willAddQuestion.must === true ? "1" : "0",
          quesType: this.willAddQuestion.fillable
            ? "3"
            : this.willAddQuestion.type,
          tag: this.tagsToString(this.willAddQuestion.tags),
          // orderIndex: this.detail.length,
        });
        if (res.code === 200) {
          this.$message({
            type: "success",
            message: "保存成功",
          });
          this.getQuestionList();
        } else if (res.code === 401) {
          this.toLogin();
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      } else {
        // 修改问题
        let count = 0;
        if (
          this.isGetIdNumber(this.willAddQuestion.tags) === "1" &&
          this.willAddQuestion.idType !== "1"
        ) {
          count++;
        }
        // 限制身份证采集题只有一个
        for (let index = 0; index < this.detail.length; index++) {
          const element = this.detail[index];
          if (element.idType === "1") {
            count++;
          }
          if (count > 1) {
            this.$message({
              type: "info",
              message: "身份证采集题限制只能存在一个",
            });
            this.dialogLoading = false;
            return;
          }
        }

        let res = await updateQuestion({
          // 根据标签判断是否为身份证采集题目
          idType: this.isGetIdNumber(this.willAddQuestion.tags),
          isLib: this.isAnyChangeAfterEdit(this.willAddQuestion),
          id: this.willAddQuestion.id,
          paperId: this.wjId,
          quesDesc: this.willAddQuestion.title,
          quesOption: this.optionsToString(this.willAddQuestion.options),
          quesRequired: this.willAddQuestion.must === true ? "1" : "0",
          quesType: this.willAddQuestion.fillable
            ? "3"
            : this.willAddQuestion.type,
          tag: this.tagsToString(this.willAddQuestion.tags),
        });
        if (res.code === 200) {
          this.$message({
            type: "success",
            message: "保存成功",
          });
          this.getQuestionList();
        } else if (res.code === 401) {
          this.toLogin();
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      }
      this.dialogLoading = false;
      this.outerVisible = false;
    },

    // 判断编辑问题后是否存在修改
    isAnyChangeAfterEdit(question) {
      if (this.isObjectEqual(question, this.oldObj)) {
        return "0";
      }
      return "1";
    },

    // 判断两个对象是否相等
    isObjectEqual(obj1, obj2) {
      let o1 = obj1 instanceof Object;
      let o2 = obj2 instanceof Object;
      if (!o1 || !o2) {
        // 如果不是对象 直接判断数据是否相等
        return obj1 === obj2;
      }
      // 判断对象的可枚举属性组成的数组长度
      if (Object.keys(obj1).length !== Object.keys(obj2).length) {
        return false;
      }
      for (let attr in obj1) {
        let a1 =
          Object.prototype.toString.call(obj1[attr]) == "[object Object]";
        let a2 =
          Object.prototype.toString.call(obj2[attr]) == "[object Object]";
        let arr1 =
          Object.prototype.toString.call(obj1[attr]) == "[object Array]";
        if (a1 && a2) {
          // 如果是对象继续判断
          return isObjectEqual(obj1[attr], obj2[attr]);
        } else if (arr1) {
          // 如果是数组，转化成字符串判断
          if (obj1[attr].toString() != obj2[attr].toString()) {
            return false;
          }
        } else if (obj1[attr] !== obj2[attr]) {
          // 不是对象的就判断数值是否相等
          return false;
        }
      }
      return true;
    },

    // 通过tagID获取tag名称
    getTagsNameByTagIds(tagStr) {
      let databaseTags = this.tagOptions;
      let tagIdArr = tagStr.split(",");
      let resArr = [];
      // 对问题拥有的tag进行循环
      for (let outerIndex = 0; outerIndex < tagIdArr.length; outerIndex++) {
        const questionTagId = tagIdArr[outerIndex];
        // 对数据库中的tag进行循环
        for (
          let innerIndex = 0;
          innerIndex < databaseTags.length;
          innerIndex++
        ) {
          const databaseTag = databaseTags[innerIndex];
          // 有匹配的id, 存入返回结果数组中
          if (questionTagId === databaseTag.id) {
            resArr.push(databaseTag.name);
          }
        }
      }
      return resArr;
    },

    // 点击编辑问题按钮
    editorQuestion(item) {
      if (this.status === "0") {
        this.$message({
          type: "info",
          message: "请暂停问卷后进行编辑",
        });
        return;
      }

      this.willAddQuestion.title = item.quesDesc;
      this.willAddQuestion.type = item.quesType === "3" ? "2" : item.quesType;
      this.willAddQuestion.options = this.optionsToArray(item.quesOption);
      this.willAddQuestion.text = item.text;
      this.willAddQuestion.must = item.quesRequired === "1" ? true : false;
      this.willAddQuestion.id = item.id;
      this.willAddQuestion.tags = this.getTagsNameByTagIds(item.tag);
      this.willAddQuestion.fillable = item.quesType === "3" ? true : false;
      this.willAddQuestion.idType = item.idType;

      this.oldObj = JSON.parse(JSON.stringify(this.willAddQuestion));
      this.dialogTitle = "编辑题目";
      this.isQuestionChange = true;
      this.outerVisible = true;
    },

    // 添加选项
    addOption() {
      this.willAddQuestion.options.push({
        title: "",
      });
    },

    // 删除选项
    deleteOption(index) {
      this.willAddQuestion.options.splice(index, 1);
    },

    // 切换问题类型
    typeChange(value) {
      this.willAddQuestion.type = value;
      this.willAddQuestion.text = "";
      this.row = 1;
    },

    // 将选项数组转化成字符串
    optionsToString(options) {
      let str = "";
      if (options.length < 1) {
        return str;
      }

      options.forEach((item, index) => {
        str +=
          String.fromCharCode(65 + index) +
          ":" +
          item.title.replace(/\\/, "\\\\").replace(/,/g, "\\,") +
          ",";
      });
      str = str.slice(0, str.length - 1);
      return str;
    },

    // 将选项字符串转化为数组
    optionsToArray(options) {
      let arr = [];

      // 填空题options为Null直接返回空数组
      if (!options) {
        return arr;
      }

      options.split(",").forEach((item, index) => {
        arr.push({
          title: item.slice(2),
          id: index,
        });
      });
      return arr;
    },

    // 将标签数组转化成字符串
    tagsToString(tags) {
      let str = "";
      if (tags === []) {
        return str;
      }
      tags.forEach((item) => {
        if (item.id === "身份证采集") {
          this.willAddQuestion.idType = "1";
        }
        for (let index = 0; index < this.tagOptions.length; index++) {
          const element = this.tagOptions[index];
          if (item === element.name) {
            str += element.id + ",";
          }
        }
      });
      str = str.slice(0, str.length - 1);
      return str;
    },

    // 通过标签判断是否为采集身份证信息
    isGetIdNumber(tags) {
      for (let index = 0; index < tags.length; index++) {
        let flag = tags[index];
        if (flag === "身份证采集") {
          return "1";
        }
      }
      return "0";
    },

    // 限制选项中不能有英文逗号
    limitCommaInOptions() {
      let options = this.willAddQuestion.options;
      if (options === []) {
        return true;
      } else {
        for (let index = 0; index < options.length; index++) {
          const element = options[index];
          if (element.title.includes(",")) {
            return false;
          }
        }
      }
      return true;
    },
    // methods末端
  },

  // token失效，需要重新获取token
  async toLogin() {
    let { code, data, message } = await getToken();
    if (code === 200) {
      sessionStorage.setItem("LocalToken", data.token);
      location.reload();
    } else {
      this.$message({
        type: "error",
        message: message,
      });
    }
  },
};
</script>
<style>
.Design {
}

.Design .dialog {
  text-align: left;
}

.Design .questionTitle {
  display: inline-block;
  width: 80%;
  font-size: 16px;
  color: #303133;
}

.Design .addOptionButton {
  display: inline-block;
  margin-left: 80px;
}

.box-card {
  width: 100%;
  text-align: left;
}

.box-card .item {
  width: 100%;
  overflow-y: scroll;
}

.Design .top {
  color: #606266;
  margin-left: 20px;
  padding: 0 10px 10px 10px;
  border-bottom: 3px solid #36857a;
  font-size: 15px;
  line-height: 22px;
  text-align: left;
}

.sub-title {
  width: 80px;
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}
</style>